import {

    ownerImgs1,
    ownerImgs2,
    ownerImgs3,
    ownerImgs4,
    ownerImgs5,
    ownerImgs6,
    ownerImgs7,
    ownerImgs8,
    ownerImgs9,
    ownerImgs10

} from '../../utils/allImgs'



const dataOwnerImgs = [
    ownerImgs1,
    ownerImgs2,
    ownerImgs3,
    ownerImgs4,
    ownerImgs5,
    ownerImgs6,
    ownerImgs7,
    ownerImgs8,
    ownerImgs9,
    ownerImgs10
]



export default dataOwnerImgs