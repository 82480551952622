import {
	cat1,
	cat2,
	cat3,
	cat4,
	cat5,
	cat6,
	check,
	iconsH1,
	iconsH2,
	iconsH3,
	iconsH4

} from '../../utils/allImgs'

const data = [
	{
		OurMentors:{
			teamItem:[
				{
					img:cat1,
					text:"NFT Art",
					class:"art-bg1"
				},
				{
					img:cat2,
					text:"Game Cards",
					class:"art-bg2"
				},
				{
					img:cat3,
					text:"Music",
					class:"art-bg3"
				},
				{
					img:cat4,
					text:"Collectabiles",
					class:"art-bg4"
				},
				{
					img:cat5,
					text:"Domains",
					class:"art-bg5"
				},
				{
					img:cat6,
					text:"Purchases",
					class:"art-bg6"
				}
			]
		},
		How_to_Participate_Sect:{
			How_to_Participate_Left_Contnet:"Create and sell your NFTs",
			How_to_Participate_right_Contnet:[
				{
					img:check,
					title:"Easy To Use and checkout easil"
				},
				{
					img:check,
					title:"Free Integrated Wallet"
				},
				{
					img:check,
					title:"Secondary Market Trading"
				},
				{
					img:check,
					title:"No knowledge of crypto required"
				}
			],
		},
		HowItWorks:{
			HowItWorksContents:[
				{
					img:iconsH1,
					class:"icon-b1",
					title:"Set up your wallet",
					text:"it is required to install Metamask wallet as a browser extension, Then you should configure Metamask to connect to required Network."
				},
				{
					img:iconsH2,
					class:"icon-b2",
					title:"Mint Your NFT",
					text:"Mint your NFT assets with included IPFS with easiest way, just upload and fill your nft info and click the mint button, and Happpy Minting"
				},
				{
					img:iconsH3,
					class:"icon-b3",
					title:"List NFTs For Sale",
					text:"once you click the mint button the metamask wallet will pop up to confirm the minting function, just confirm and your nft will be listed"
				},
				{
					img:iconsH4,
					class:"icon-b4",
					title:"Buy and Sell NFTs",
					text:"once the item is listed, congrats! Now you are ready to sell and buy NFTs, You just need to specify your NFT Price and click offer"
				}
			],
		},
		nftSection:{
			myTab:[
				{text:"all"},
				{text:"Artworks"},
				{text:"Gift Cards"},
				{text:"Collectibles"},
				{text:"Music"},
				{text:"Domains"},
				{text:"purchases"}
			]
		},
		Faq:{
			FaqQ:[
				{
					id:"text1",
					title:"What are NFTs?",
					text:"NFTs, or non-fungible tokens, are virtual tokens that represent ownership of something inherently distinct and scarce, such as a piece of art or music, and cannot be mutually exchanged. Essentially, buying an NFT is like buying a piece of art, only digital. Instead of owning a picture to hang on your wall, you own a digital file. NFTs have quickly become one of the fastest growing types of digital asset, with investors seeking to own these unique, blockchain-based instruments. While NFTs have been around since 2014, they have continued to increase in popularity in recent years, with NFT sales reaching $25 billion in 2021."
				},
				{
					id:"text2",
					title:"NFTs Vs. Cryptocurrency - What's the difference?",
					text:"NFTs are typically built with the same programming as cryptocurrency, but that is the extent of their similarities. Cryptocurrencies are fungible, meaning they can be traded for one another. For example, one Bitcoin has the same value as another Bitcoin. All NFTs are unique, rendering them non-fungible, meaning two different NFTs will hold different values. Cryptocurrencies are built using blockchain technology, which is a distributed public ledger that records transactions. NFTs typically exist on the Ethereum blockchain, although other blockchains support them as well."
				},
				{
					id:"text3",
					title:"What can I use to purchase NFT on i9 NFT Marketplace?",
					text:"You can buy NFTs with credit or debit cards, ACH, direct deposits, wires, cash (through i9 Bank), or any of the 40 cryptocurrencies on our exchange."
				},
				{
					id:"text4",
					title:"How can I purchase an NFT on i9 NFT Marketplace?",
					text:"You must be logged in to your verified and funded Metamask account to make a purchase 1- Navigate to the NFT you’d like to purchase and click the Buy Now button, 2- Using your preferred device, click the PAY WITH i9 NFT button,3- Decide which currency you’d like to pay with. To pay with COIN click Confirm at the top of the page. To pay with a different currency in your wallet, simply select the one you prefer on the right side of the page. The purchase is now complete. You can navigate to your account by clicking My Account on the upper right side of the page and then navigating to Wallet, where your NFTs will be stored."
				}
			]
		},
		dataHeadings:{
		},
		NewNFTs:{
			popularOfTheWeekList:[
				{
					title:"astronauta 1 NFT",
					OwnerKey:"Item Owner",
					OwnerVal:"0xebFff...0cA3f0C",
					CategoryKey:"Item Category",
					CategoryVal:"NFT Art",
					PriceKey:"Item Price",
					PriceVal:"0.32 ETH"
				},
				{
					title:"astronauta 2 NFT",
					OwnerKey:"Item Owner",
					OwnerVal:"0xB4BB8...6913c79",
					CategoryKey:"Item Category",
					CategoryVal:"Collectabiles",
					PriceKey:"Item Price",
					PriceVal:"0.32 ETH"
				},
				{
					title:"astronauta 3 NFT",
					OwnerKey:"Item Owner",
					OwnerVal:"7aeu342...y5A3712",
					CategoryKey:"Item Category",
					CategoryVal:"Game Cards",
					PriceKey:"Item Price",
					PriceVal:"0.32 ETH"
				}
			]
		},
		CategoriesDate:{
			CategoriesList:[
				{
					img:cat1,
					title:"NFT Art",
					val:"NFT"
				},
				{
					img:cat2,
					title:"Game Cards",
					val:"Game"
				},
				{
					img:cat3,
					title:"Collectabiles",
					val:"Collectabiles"
				},
				{
					img:cat4,
					title:"Music",
					val:"Music"
				},
				{
					img:cat5,
					title:"Domains",
					val:"Domains"
				},
				{
					img:cat6,
					title:"Purchases",
					val:"Purchases"
				}
			]
		}
	}
]



export default data